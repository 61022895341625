<template>
    <div class="flex flex-col">
        <warning
            icon="regular/multiple-neutral-2"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchUsers" />

                <activix-tooltip placement="right" :content="$t('users.global.goToAccount')">
                    <router-link
                        class="flex ml-2 link-grey"
                        :to="{ name: 'accounts.update', params: { id: contextAccount.id } }"
                        v-if="canAccessAccountCard"
                    >
                        <icon name="regular/shop" />
                    </router-link>
                </activix-tooltip>
            </portal>

            <portal to="content-header-right">
                <activix-tooltip
                    placement="left"
                    :content="showInactive ? $t('users.list.hideInactive') : $t('users.list.showInactive')"
                    v-if="canToggleInactive"
                >
                    <activix-switcher class="mt-1" v-model="showInactive" />
                </activix-tooltip>
            </portal>

            <div class="box" :class="{ loading: isLoading }">
                <div class="box-header | h-auto p-6 flex justify-between">
                    <activix-tooltip :content="hasSuspendedUsers ? $t('users.list.hasSuspendedUsers') : ''">
                        <span>
                            <activix-button type="primary" :disabled="hasSuspendedUsers" @click="onCreate">
                                <icon name="bold/add" class="mr-2 text-sm" />
                                {{ $t('general.add') }}
                            </activix-button>
                        </span>
                    </activix-tooltip>
                    <input
                        class="form-control w-auto"
                        type="text"
                        :placeholder="$t('search.search')"
                        v-model="tableState.searchToken"
                    />
                </div>
                <div class="box-body | p-0">
                    <el-table
                        class="w-full border-t-2 border-b"
                        :border="true"
                        :data="paginatedUsers"
                        :default-sort="{ prop: 'name', order: 'ascending' }"
                        :stripe="true"
                        :row-class-name="childRowClassName"
                        @sort-change="updateTableSorting"
                    >
                        <el-table-column
                            header-align="center"
                            min-width="150"
                            prop="name"
                            :label="$t('users.list.table.headings.name')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                <router-link
                                    :class="{ 'text-grey-600': !!childRowClassName({ row: user }) }"
                                    :to="{ name: 'users.update', params: { id: user.id } }"
                                >
                                    {{ user.name }}
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            min-width="135"
                            prop="phones"
                            :label="$t('users.list.table.headings.phones')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                <div :key="index" v-for="(phone, index) in user.phones">
                                    {{ phone }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            min-width="260"
                            prop="email"
                            :label="$t('users.list.table.headings.email')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            min-width="110"
                            prop="role"
                            :label="$t('users.list.table.headings.role')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            min-width="90"
                            prop="divisions"
                            :label="$t('users.list.table.headings.divisions')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                <div :key="division.id" v-for="division in user.divisions">
                                    {{ $t(`divisions.${division.name}`) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="locale"
                            width="90"
                            :label="$t('users.list.table.headings.locale')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                {{ $t(`database.${user.locale}`) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="imap"
                            align="center"
                            width="65"
                            min-width="65"
                            label="IMAP"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                <icon name="regular/check-double" v-if="user.imap" />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="active"
                            align="center"
                            width="65"
                            min-width="65"
                            :label="$t('general.active')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: user }">
                                <icon name="regular/check-double" v-if="user.active" />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="suspended"
                            align="center"
                            width="85"
                            min-width="85"
                            :label="$t('general.suspended')"
                            :sortable="true"
                            v-if="hasSuspendedUsers"
                        >
                            <template slot-scope="{ row: user }">
                                <icon name="regular/check-double" v-if="user.suspended" />
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            column-key="delete"
                            width="80"
                            v-if="canImpersonate || canDeleteUser"
                        >
                            <div
                                class="flex items-center justify-center text-lg text-grey-600"
                                slot-scope="{ row: user }"
                            >
                                <activix-tooltip :content="$t('users.global.impersonate')" v-if="canImpersonate">
                                    <icon name="regular/login-1" class="link-grey" @click="impersonate(user)" />
                                </activix-tooltip>
                                <activix-tooltip :content="$t('general.delete')" v-if="canDeleteUser">
                                    <icon
                                        :name="$icons.trash"
                                        class="link-grey | hover:text-red-500"
                                        :class="{ 'ml-3': canImpersonate }"
                                        @click="onDelete(user.id)"
                                    />
                                </activix-tooltip>
                            </div>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        class="p-6"
                        :layout="paginationLayout"
                        :background="true"
                        :current-page.sync="tableState.currentPage"
                        :page-size.sync="tableState.perPage"
                        :page-sizes="tableState.pageOptions"
                        :pager-count="5"
                        :total="filteredUsers.length"
                        @size-change="updateTablePerPage"
                        v-if="tableData.length > 0"
                    />
                </div>
            </div>
        </template>

        <activix-confirm-modal
            type="warning"
            :content="$t('users.list.deleteModal')"
            :opened.sync="deleteModal.opened"
            @approve="deleteUser"
            @closed="deleteModal.userId = null"
        />
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import { mapState } from 'pinia';
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import Warning from '../../components/Warning.vue';
    import Account from '../../entities/Account.js';
    import Role from '../../entities/Role.js';
    import User from '../../entities/User.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'UsersList',

        components: {
            Warning,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                showInactive: false,
                users: [],
                deleteModal: {
                    opened: false,
                    userId: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.users']);
            },

            canToggleInactive() {
                return this.authUser.isAdmin() || this.authUser.isDirector();
            },

            canAccessAccountCard() {
                return this.authUser.hasAccessTo('accounts.update');
            },

            canImpersonate() {
                return this.authUser.isAdmin() && this.contextAccount.id != Account.ACTIVIX;
            },

            canDeleteUser() {
                return this.authUser.isProgrammer();
            },

            filteredUsers() {
                const searchedData = this.searchData(
                    this.tableState.searchToken,
                    this.tableData.filter(user => {
                        let authorized = false;

                        if (
                            this.authUser.isAdmin() ||
                            this.authUser.isDirector() ||
                            (this.authUser.isBdcDirector() && Role.canBeEditedByBdcDirector(user.role_id)) ||
                            this.authUser.id == user.id
                        ) {
                            authorized = true;
                        }

                        return authorized && (this.showInactive || user.active);
                    }),
                );

                return orderBy(searchedData, [this.tableState.sorting.column], [this.tableState.sorting.order]);
            },

            paginatedUsers() {
                return this.filteredUsers.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },

            tableData() {
                if (!this.validAccount) {
                    return [];
                }

                return this.users.map(user => {
                    return {
                        active: user.active,
                        suspended: user.suspended,
                        divisions: user.divisions,
                        email: user.email,
                        id: user.id,
                        locale: user.locale,
                        name: user.fullName,
                        parent_user_id: user.parent_user_id,
                        phones: user.phones.map(phone => phone.formatedNumberWithExtension),
                        role: user.role,
                        role_id: user.role_id,
                        sex: user.sex,
                        imap: user.email_config ? user.email_config.active : false,
                    };
                });
            },

            validAccount() {
                return !!this.contextAccount.id;
            },

            hasSuspendedUsers() {
                return !!this.tableData.filter(user => user.suspended).length;
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchUsers();
                },
            },
        },

        methods: {
            childRowClassName({ row: user }) {
                return user.parent_user_id ? 'text-grey-600' : '';
            },
            onCreate() {
                this.$router.push({ name: 'users.store' });
            },

            onDelete(id) {
                this.deleteModal.opened = true;
                this.deleteModal.userId = id;
            },

            async fetchUsers() {
                if (!this.validAccount || !this.contextAccount.id) {
                    return;
                }

                this.$wait.start('fetching.users');

                try {
                    const users = await this.$api.users.index({
                        include: ['divisions', 'userPhones', 'emailConfig'],
                        filter: {
                            accountId: this.contextAccount.id,
                        },
                    });

                    this.users = users.map(user => new User(user)).sort((a, b) => a.fullName.localeCompare(b.fullName));

                    this.$wait.end('fetching.users');
                } catch (error) {
                    this.$wait.end('fetching.users');
                    this.$notify.error(this.$t('users.alerts.index.error'));

                    throw error;
                }
            },

            async deleteUser() {
                const id = this.deleteModal.userId;

                if (!id) {
                    return;
                }

                const index = this.users.findIndex(user => user.id == id);

                if (index !== -1) {
                    this.users.splice(index, 1);
                }

                try {
                    this.$notify.success(this.$t('users.alerts.destroy.success'));
                    await this.$api.users.destroy(id);
                } catch (error) {
                    this.$notify.error(this.$t('users.alerts.destroy.error'));

                    throw error;
                }
            },

            async impersonate(user) {
                this.$ls.remove('context');

                await this.$nextTick();

                this.$auth.impersonate({
                    data: {
                        userId: user.id,
                    },
                });
            },
        },
    };
</script>
